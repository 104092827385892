import { reducer as authReducer } from './auth/reducer';
import { reducer as userReducer } from './user/reducer';
import { reducer as nftReducer } from './nft/reducer';
import { reducer as auctionReducer } from './auction/reducer';
import { reducer as configReducer } from './config/reducer';
import { reducer as orderReducer } from './orders/reducer';
import { reducer as dropsReducer } from './drops/reducer';
import { reducer as customersReducer } from './customers/reducer';
import { reducer as snapshotsReducer } from './snapshots/reducer';
import { reducer as promoCodesReducer } from './promoCodes/reducer';
import { reducer as discordReducer } from './discord/reducer';
import { reducer as blockchainReducer } from './blockchain/reducer';
import { reducer as newstickersReducer } from './newstickers/reducer';
import { reducer as statsReducer } from './stats/reducer';
import { reducer as storageReducer } from './storage/reducer';
import { reducer as socialMediaReducer } from './social/reducer';

export const rootReducer = {
  auth: authReducer,
  user: userReducer,
  nft: nftReducer,
  auction: auctionReducer,
  config: configReducer,
  order: orderReducer,
  drops: dropsReducer,
  customers: customersReducer,
  snapshots: snapshotsReducer,
  promoCodes: promoCodesReducer,
  discord: discordReducer,
  blockchain: blockchainReducer,
  newstickers: newstickersReducer,
  stats: statsReducer,
  storage: storageReducer,
  socialMedia: socialMediaReducer,
};

import { lazy } from 'react';
import { IAppRouterProps } from './types';
import routes from './paths';
import { Redirect } from 'react-router';

const Login = lazy(() => import('pages/account/Login'));
const Logout = lazy(() => import('pages/account/Logout'));
const Dashboard = lazy(() => import('pages/dashboard'));
const Users = lazy(() => import('pages/users/UsersList'));
const UserProfile = lazy(() => import('pages/users/UserProfile'));
const NFTsList = lazy(() => import('pages/nfts/NFTsList'));
const NFTDetails = lazy(() => import('pages/nfts/NFTDetails'));
const CreateNFT = lazy(() => import('pages/nfts/NFTCreate'));
const EditNFT = lazy(() => import('pages/nfts/NFTEdit'));
const AuctionsList = lazy(() => import('pages/auctions/AuctionsList'));
const Auction = lazy(() => import('pages/auctions/Auction'));
const ConfigurationsList = lazy(
  () => import('pages/configurations/ConfigsList'),
);
const ConfigurationTypesList = lazy(
  () => import('pages/configurations/ConfigTypesList'),
);
const Config = lazy(() => import('pages/configurations/Config'));

const NewstickersList = lazy(() => import('pages/newstickers/NewstickersList'));
const NewstickerEdit = lazy(() => import('pages/newstickers/Newsticker'));

const Orders = lazy(() => import('pages/orders/OrdersList'));

const DropsList = lazy(() => import('pages/drops/DropsList'));
const DropEdit = lazy(() => import('pages/drops/DropEdit'));
const Drop = lazy(() => import('pages/drops/Drop'));

const CustomersList = lazy(() => import('pages/customers/CustomersList'));
const Customer = lazy(() => import('pages/customers/Customer'));

const SnapshotsList = lazy(() => import('pages/snapshots/SnapshotList'));
const Snapshot = lazy(() => import('pages/snapshots/Snapshot'));
const Blockchain = lazy(() => import('pages/blockchain'));
const ContractTemplate = lazy(
  () => import('pages/blockchain/ContractsTemplates/ContractTemplate'),
);
const EditContractTemplate = lazy(
  () => import('pages/blockchain/ContractsTemplates/EditContractTemplate'),
);
const Contract = lazy(() => import('pages/blockchain/Contracts/Contract'));
const CreateContract = lazy(
  () => import('pages/blockchain/Contracts/EditContract/EditContract'),
);
const CreateToken = lazy(() => import('pages/blockchain/Tokens/CreateToken'));

export const publicRoutes: IAppRouterProps[] = [
  {
    path: routes.login,
    component: <Login />,
  },
  {
    path: routes.logout,
    component: <Logout />,
  },
];

export const protectedRoutes: IAppRouterProps[] = [
  {
    path: routes.home,
    component: <Redirect to={routes.dashboard} />,
  },
  {
    // empty
    path: routes.dashboard,
    component: <Dashboard />,
  },
  {
    path: routes.users,
    component: <Users />,
  },
  {
    path: routes.user,
    component: <UserProfile />,
  },
  {
    path: routes.nfts,
    component: <NFTsList />,
  },
  {
    path: routes.createNft,
    component: <CreateNFT />,
  },
  {
    path: routes.editNft,
    component: <EditNFT />,
  },
  {
    path: routes.nftsDetails,
    component: <NFTDetails />,
  },
  {
    path: routes.auctions,
    component: <AuctionsList />,
  },
  {
    path: routes.auction,
    component: <Auction />,
  },
  {
    path: routes.config,
    component: <Config />,
  },
  {
    path: routes.configTypes,
    component: <ConfigurationTypesList />,
  },
  {
    path: routes.configType,
    component: <ConfigurationsList />,
  },

  {
    path: routes.newstickers,
    component: <NewstickersList />,
  },
  {
    path: routes.newsticker,
    component: <NewstickerEdit />,
  },
  {
    path: routes.orders,
    component: <Orders />,
  },
  {
    path: routes.drops,
    component: <DropsList />,
  },
  {
    path: routes.dropsEdit,
    component: <DropEdit />,
  },
  {
    path: routes.dropsCreate,
    component: <DropEdit />,
  },
  {
    path: routes.drop,
    component: <Drop />,
  },
  {
    path: routes.customersSnapshots,
    component: <SnapshotsList />,
  },
  {
    path: routes.customersSnapshot,
    component: <Snapshot />,
  },
  {
    path: routes.customers,
    component: <CustomersList />,
  },
  {
    path: routes.customer,
    component: <Customer />,
  },
  {
    path: routes.blockchain,
    component: <Blockchain />,
  },
  {
    path: routes.blockchainTemplateEdit,
    component: <EditContractTemplate />,
  },
  {
    path: routes.blockchainTemplate,
    component: <ContractTemplate />,
  },
  {
    path: routes.blockchainSmartContract,
    component: <Contract />,
  },
  {
    path: routes.blockchainSmartContractEdit,
    component: <CreateContract />,
  },
  {
    path: routes.blockchainTokenCreate,
    component: <CreateToken />,
  },
];

import { Environment } from './types';

const config = {
  authToken: '/api/v1/auth/token',
  tokenRefresh: '/api/v1/auth/token/refresh',
  tokenEndpointKeycloak: '/protocol/openid-connect/token',
  usersMe: '/api/users/me',

  drops: '/api/drops',
  dropByIdOrServiceName: (id: string) => `/api/drops/${id}`,
  dropFiles: (idOrServiceName: string) => `/api/drops/${idOrServiceName}/files`,

  nfts: '/api/nfts/',
  nftById: (id: string) => `/api/nfts/${id}`,
  nftFiles: '/api/nfts/files',

  categories: '/api/categories',
  categoryById: (id: number) => `/api/categories/${id}`,

  templates: '/api/ucts',
  templateItems: (nftId: string) => `/api/nfts/${nftId}/ucs`,
  templateItem: (nftId: string, id: string) => `/api/nfts/${nftId}/ucs/${id}`,
  templateById: (id: string) => `/api/ucts/${id}`,
  auction: '/api/auction',
  auctionNfts: '/api/nfts/auctions',
  auctionById: (id: string) => `/api/auction/${id}`,
  auctionLogs: (id: string) => `/api/auction/${id}/logs`,
  promoCodes: '/api/promo-codes',

  socialPosts: '/api/social/posts',

  configs: `/api/configs`,
  configTypes: `/api/configs/types`,
  configType: (type: string) => `/api/configs/types/${type}`,
  configsByIdOrName: (idOrName: string | number) => `/api/configs/${idOrName}`,
  configsFiles: (id: string) => `/api/configs/${id}/files`,
  copyPasteConfig: (fromId: number, toId: number) =>
    `/api/configs/${toId}/${fromId}`,

  newstickers: '/api/newstickers',
  newstickerById: (id: number) => `/api/newstickers/${id}`,

  customers: '/api/customers',
  customer: (id: string) => `/api/customers/${id}`,
  customersExport: (format: string) => `/api/customers/export/${format}`,
  customerSnapshots: '/api/customer-snapshot',
  customerSnapshotById: (id: string) => `/api/customer-snapshot/${id}`,
  customerSnapshot: (id: string) => `/api/customer-snapshot/${id}/customers`,
  customerSnapshotRefresh: (id: string) =>
    `/api/customer-snapshot/${id}/refresh`,
  customerSnapshotSendMail: (id: string) =>
    `/api/customer-snapshot/${id}/action/mail/send`,
  customerSnapshotSetRole: (id: string) =>
    `/api/customer-snapshot/${id}/action/discord/roles/set`,
  customerSnapshotSendNft: (snapshotId: string) =>
    `/api/customer-snapshot/${snapshotId}/action/nfts/send`,
  customerSendLink: (id: string) => `/api/customers/${id}/send-link`,
  exportCustomerSnapshot: (id: number, format: 'csv' | 'json') =>
    `/api/customer-snapshot/${id}/export/${format}`,

  discordRoles: '/api/discord/roles',
  discordRolesAssign: '/api/discord/roles/assign',
  order: (oId: number) => `/api/orders/${oId}`,
  orders: '/api/orders',
  ordersStatuses: '/api/orders/statuses',

  users: '/api/v1/users',
  user: (id: string) => `/api/v1/users/${id}`,

  encrypt: '/api/crypt/encrypt',

  blockchain: (id: string) => `/api/blockchains/${id}`,
  blockchains: '/api/blockchains/',
  smartContract: (scId: number) => `/api/blockchains/contracts/${scId}`,
  smartContracts: '/api/blockchains/contracts',
  smartContractsDeploy: '/api/blockchains/contracts/deploy',
  smartContractTemplate: (templateId: number) =>
    `/api/blockchains/contracts/templates/${templateId}`,
  smartContractTemplates: '/api/blockchains/contracts/templates',
  prepareFreeze: (contractId: number) =>
    `/api/blockchains/contracts/${contractId}/freeze/prepare/`,
  freeze: (freezeId: number) => `/api/blockchains/contracts/freeze/${freezeId}`,

  tokens: '/api/blockchains/tokens',
  metadata: '/api/metadata/',

  stats: 'api/stats',

  files: '/api/files',
};

const LOCAL_ENVIRONMENT: Environment = {
  ipServiceURL: 'https://oauth.auth-three.com',
  apiBaseURL: 'http://localhost:8080',
  storageURL: 'https://storage.dev.thedematerialised.com',
  frontendBaseUrl: 'http://localhost:3001',
  backofficeBaseUrl: 'http://localhost:3000',
  keycloakUrl: 'https://accounts.dev.thedematerialised.com/auth/realms/thedmat',
  clientId: 'backoffice-dev',
  useKeycloak: true,
};

const DEV_ENVIRONMENT: Environment = {
  ipServiceURL: 'https://oauth.auth-three.com',
  apiBaseURL: 'https://api.dev.thedematerialised.com',
  storageURL: 'https://storage.dev.thedematerialised.com',
  frontendBaseUrl: 'https://dev.thedematerialised.com',
  backofficeBaseUrl: 'https://admin.dev.thedematerialised.com',
  keycloakUrl: 'https://accounts.dev.thedematerialised.com/auth/realms/thedmat',
  clientId: 'backoffice-dev',
  useKeycloak: true,
};

const STAGING_ENVIRONMENT: Environment = {
  ipServiceURL: 'https://oauth.auth-three.com',
  apiBaseURL: 'https://api.staging.thedematerialised.com',
  storageURL: 'https://storage.staging.thedematerialised.com',
  frontendBaseUrl: 'https://staging.thedematerialised.com',
  backofficeBaseUrl: 'https://admin.staging.thedematerialised.com',
  keycloakUrl:
    'https://accounts.staging.thedematerialised.com/auth/realms/thedmat',
  clientId: 'backoffice-stag',
  useKeycloak: true,
};

const PRODUCTION_ENVIRONMENT: Environment = {
  ipServiceURL: 'https://oauth.auth-three.com',
  apiBaseURL: 'https://api.thedematerialised.com',
  storageURL: 'https://storage.thedematerialised.com',
  frontendBaseUrl: 'https://thedematerialised.com',
  backofficeBaseUrl: 'https://admin.thedematerialised.com',
  keycloakUrl: 'https://accounts.thedematerialised.com/auth/realms/thedmat',
  clientId: 'backoffice',
  useKeycloak: true,
};

const getNodeEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      // can be change to .dev for connect to dev
      // don't forget to relogin
      return LOCAL_ENVIRONMENT;
    case 'dev':
      return DEV_ENVIRONMENT;
    case 'staging':
      return STAGING_ENVIRONMENT;
    default:
      return PRODUCTION_ENVIRONMENT;
  }
};

export const ENVIRONMENT = getNodeEnv();

export default { ...config, env: ENVIRONMENT };
